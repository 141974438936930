import React from "react";
import "../css/footer.scss"

class Footer extends React.Component {
    render() {
        return (
            <>
                <footer className="footer bg-light">
                    <div className="container">
                        <div>
                            <div>
                                Hangliding.spb.ru
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;